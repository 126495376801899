




















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Table, TableFieldType } from '@/models/components'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'
import { PaginatedQueryResult } from '@/models'
import IndicatorResult from '@/components/componentTypes/indicators/Result.vue'
import TableField from './TableField.vue'
import InlineBlock from './InlineBlock.vue'
import TableButton from './TableButton.vue'
import numeral from '@/plugins/numeral'
import gql from 'graphql-tag'
import { ViewTableCell } from '.'
import draggable from 'vuedraggable'
import { alert } from '@/components/dialogs'
import { MoveEvent } from 'sortablejs'
import { Debounce } from '@/utils/decorators'

@Component({
  components: {
    TableField,
    IndicatorResult,
    CollectionWatcher,
    TableButton,
    InlineBlock,
    draggable
  },
  apollo: {
    data: {
      query: gql`query paginated_result ($page : BigInt, $limit : BigInt, $tableId: ID, $filterId : ID, $filterOptions : JSON, $sortBy : String, $sortType : String) {
        data: tableResult (page: $page, limit: $limit, tableId: $tableId, filterId: $filterId, filterOptions: $filterOptions, sortBy: $sortBy, sortType: $sortType) {
          _id
          totalCount
          totalPages
          hasNextPage
          hasPreviousPage
          items {
            _id
            data
          }
        }
      }`,
      variables () {
        return {
          page: this.table.unlimited ? undefined : this.pagination.page,
          limit: this.table.unlimited ? undefined : this.pagination.itemsPerPage,
          tableId: this.table._id,
          filterId: this.filterId,
          filterOptions: this.filterOptions,
          sortBy: this.pagination.sortBy[0],
          sortType: this.pagination.sortDesc[0] ? 'DESC' : 'ASC'
        }
      },
      fetchPolicy: 'network-only',
      skip () {
        return !!this.externalPagination || !!this.externalData || this.externalLoading
      }
    }
  }
})
export default class TableViewItems extends Vue {
  @Prop({ type: Object, required: true }) table !: Table
  @Prop({ type: String }) filterId ?: string
  @Prop({ type: Object }) filterOptions ?: Record<string, any>
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>
  @Prop({ type: Object }) externalData ?: PaginatedQueryResult<any>
  @Prop({ type: Object }) externalPagination ?: any
  @Prop({ type: Array }) externalSelection ?: any[]
  @Prop({ type: Boolean }) externalLoading ?: boolean
  @Prop({ type: Boolean, default: true }) fixedHeader ?: boolean

  data : PaginatedQueryResult<any> | null = null

  internalSelection : any[] = []
  manualPageEntry = false
  internalLoading = false
  expandedFields : any[] = []
  expandedCells : Record<string, number> = {}
  disableLoadAnimation = false

  get selectedFields () {
    return this.externalSelection || this.internalSelection
  }

  set selectedFields (v : any[]) {
    if (this.externalSelection) {
      this.$emit('update:externalSelection', v)
    } else {
      this.internalSelection = v
    }
  }

  get pagination () {
    return this.externalPagination || this.internalPagination
  }

  set pagination (v : any) {
    if (this.externalPagination) this.$emit('update:externalPagination', v)
    else this.internalPagination = v
  }

  internalPagination = {
    page: 1,
    itemsPerPage: this.table.unlimited ? -1 : this.table.defaultLimit || 25,
    sortBy: ['data'],
    sortDesc: [false],
    totalItems: 0,
    multiSort: false
  }

  @Watch('data')
  updatePagination (newData : PaginatedQueryResult<any> | null) {
    this.pagination.totalItems = newData ? newData.totalCount : 0
  }

  async processManualSort (ev : any) {
    // Validate
    if (!this.table.orderByDefault) {
      return alert('No se puede reordenar (no existe un campo de orden por defecto).')
    }
    try {
      if (!ev.moved) return
      const item = this.data!.items.splice(ev.moved.oldIndex, 1)
      this.data!.items.splice(ev.moved.newIndex, 0, ...item)
      await this.$apollo.mutate({
        mutation: gql`mutation ($tableId : ID, $filterId : ID, $filterOptions : JSON, $oldIndex : Float, $newIndex : Float) {
          tableManualSort (
            tableId : $tableId,
            filterId : $filterId,
            filterOptions : $filterOptions,
            oldIndex : $oldIndex,
            newIndex : $newIndex
          )
        }`,
        // Parameters
        variables: {
          tableId: this.table._id,
          filterId: this.filterId,
          filterOptions: this.filterOptions,
          oldIndex: ev.moved.oldIndex,
          newIndex: ev.moved.newIndex
        }
      })
    } catch (e) {
      console.error(e)
      const item = this.data!.items.splice(ev.moved.newIndex, 1)
      this.data!.items.splice(ev.moved.oldIndex, 0, ...item)
      return this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error'
      })
    }
  }

  update () {
    return this.$apollo.queries.data.refetch()
  }

  /**
   * Table headers
   */
  get headers () {
    const headers = this.table.fields.map((f, i) => !['multipleSelect', 'multipleSelectGroup'].includes(f.type) && ({
      text: f.label,
      align: 'left',
      width: ['field', 'editableField', 'indicator'].includes(f.type) ? undefined : 32,
      class: ['field', 'editableField', 'indicator'].includes(f.type) ? 'table-field-header' : 'table-icon-header',
      value: f.fieldName,
      // tslint:disable-next-line
      sortable: f.sortable == null ? f.type === 'field' || f.type === 'editableField' : f.sortable
    })).filter(f => f)
    if (this.table.sortable && this.table.orderByDefault) {
      headers.unshift({
        text: '',
        align: 'left',
        width: 32,
        class: 'table-icon-header',
        value: this.table.orderByDefault,
        sortable: false
      })
    }
    return headers
  }

  get buttons () {
    return this.table.fields.filter(f => ['multipleSelect', 'multipleSelectGroup'].includes(f.type)).map(
      f =>
        f.type === 'multipleSelectGroup' ? {
          type: 'group',
          label: f.label,
          buttonIds: f.options.buttonsIds,
          field: f
        } : (f.options.buttonsIds || []).map((buttonId : string) => ({
          buttonId: buttonId,
          field: f
        })
      )
    ).flat()
  }

  toggleSelection (item : any, value : boolean) {
    if (value) {
      if (this.isSelected(item)) return
      this.selectedFields.push(item)
    } else {
      this.$set(this, 'selectedFields', this.selectedFields.filter(f => f.id !== item.id))
    }
  }

  isSelected (item : any) {
    return !!this.selectedFields.find(f => f.id === item.id)
  }

  isExpanded (item : any, index : number) {
    return !!this.expandedFields.find(f => f.id === item.id) && this.expandedCells[item.id] === index
  }

  expand (item : any, index : any) {
    if (!this.expandedFields.find(f => f.id === item.id)) {
      this.expandedFields.push(item)
    }
    this.expandedCells[item.id] = index
  }

  collapse (item : any) {
    delete this.expandedCells[item.id]
    this.expandedFields.splice(
      this.expandedFields.findIndex(f => f.id === item.id),
      1
    )
  }

  collapseAll () {
    this.expandedFields.splice(0, this.expandedFields.length)
    for (const id in this.expandedCells) {
      delete this.expandedCells[id]
    }
  }

  get hasMultiSelect () {
    return this.table.fields.some(f => f.type === 'multipleSelect')
  }

  /**
   * Field Types
   */
  get fieldTypes () {
    return this.table.fields.map((f, index) => ({
      index,
      key: f.fieldName || index,
      type: f.type,
      options: f.options,
      width: ['field', 'editableField', 'indicator', 'multipleSelect'].indexOf(f.type) >= 0 ? undefined : 32,
      class: ['field', 'editableField', 'indicator', 'multipleSelect'].indexOf(f.type) >= 0 ? 'table-field' : 'table-icon',
      field: f.type === 'field' || f.type === 'editableField' ? (this.table.collection!.fields!.find(cf => cf.name === f.fieldName) || f) : f
    })).sort(f => f.type === 'multipleSelect' ? -1 : 0)
  }

  get items () {
    if (!this.externalData && !this.data) return []
    return (this.externalData || this.data!).items.map(i => {
      const combined = { ...i, ...i.data }

      const cssClasses : Record<string, boolean> = {}
      if (this.table.cssClassField && combined[this.table.cssClassField]) {
        combined[this.table.cssClassField].replace(/,\./g, '').split(' ').forEach((i : string) => {
          if (i.trim()) cssClasses[i.trim()] = true
        })
      }

      const cols = this.fieldTypes.map(f => !['multipleSelect', 'multipleSelectGroup'].includes(f.type) && ({
        ...f,
        colIndex: f.index,
        data: f.type === 'field' || f.type === 'editableField' ? combined[f.key] : f.options
      })).filter(f => f)

      if (this.table.sortable && this.table.orderByDefault) {
        cols.unshift({
          data: {},
          key: 'row-handle',
          colIndex: -1,
          width: 32,
          class: 'row-handle',
          type: TableFieldType.DragHandle
        } as any)
      }

      if (this.hasMultiSelect) {
        cols.unshift({
          data: {},
          colIndex: -1,
          key: 'multi-select',
          type: TableFieldType.MultipleSelect,
          width: 32
        } as any)
      }


      const row = [
        {
          id: i._id,
          raw: i,
          cols,
          cssClasses
        }
      ] as any[]

      if (this.expandedFields.some(f => f.id === i._id)) {
        const expandedCol = row[0].cols[this.expandedCells[i._id]]
        if (!expandedCol) return row
        row.push({
          id: i._id + '-expand',
          raw: i,
          cols: [],
          expandSection: true,
          blockId: expandedCol.options && expandedCol.options.blockId,
          cssClasses: {
            ...cssClasses,
            'expanded-section': true
          }
        })
      }

      return row
    }).flat()
  }

  get footerItems () {
    if (!this.data) return []
    return this.table.footer || []
  }

  get page () {
    return this.pagination.page
  }

  set page (page : number) {
    this.pagination = {
      ...this.pagination,
      page: Math.max(1, Math.min(page, this.totalPages))
    }
  }

  get totalPages () {
    return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
  }

  get totalItems () {
    return numeral(this.pagination.totalItems).format('0,000')
  }

  get pageRules () {
    return [
      (v : number) => !!v && v >= 1 && v <= this.totalPages || 'Página inválida'
    ]
  }
}
