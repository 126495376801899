var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.savedCollection && _vm.$apollo.loading)?_c('Loading'):(_vm.collection)?_c('v-card',{staticStyle:{"height":"100%"},attrs:{"flat":"","color":"rgba(255, 255, 255, 0)"}},[_c('v-card-text',{staticClass:"collection-index__card-table",staticStyle:{"height":"calc(100% - 88px)","overflow-y":"scroll"}},[_c('v-simple-table',{staticClass:"mb-2 px-1",staticStyle:{"background-color":"transparent"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"48px"}}),_c('th',{staticClass:"text-left"},[_vm._v("Combinación de Campos")]),_c('th',{staticClass:"text-left"},[_vm._v("Tipo de índice")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"48px"}},[_vm._v("Comb. Única")]),_c('th',{staticStyle:{"width":"48px"}})])]),_c('draggable',{attrs:{"tag":"tbody","handle":".index-handle","animation":200,"scroll-sensitivity":128,"force-fallback":true},model:{value:(_vm.collection.indexes),callback:function ($$v) {_vm.$set(_vm.collection, "indexes", $$v)},expression:"collection.indexes"}},_vm._l((_vm.collection.indexes),function(index,i){return _c('tr',{key:i},[_c('td',{staticClass:"index-handle",staticStyle:{"width":"48px"}},[_c('v-icon',[_vm._v("drag_handle")])],1),_c('td',[_c('Field',{staticClass:"collection-index__fields",attrs:{"name":"fields","schema":{
                type: 'collectionFieldSelect',
                label: 'Combinación de Campos',
                optional: false,
                fieldOptions: {
                  solo: true,
                  flat: true,
                  dense: true,
                  hideDetails: true,
                  multi: true,
                  showCreatedAt: true
                },
              },"environmentVariables":{ environmentId: _vm.environmentId, collectionId: _vm.collectionId },"hide-details":"","solo":"","flat":"","dense":"","label":"Combinación de Campos","value":_vm.getFieldsForIndex(index)},on:{"input":function($event){return _vm.setFieldsForIndex(index, $event)}}})],1),_c('td',[_c('v-select',{attrs:{"solo":"","flat":"","dense":"","label":"Tipo de índice","items":[
                { text: 'Descendente', value: 'DESC' },
                { text: 'Ascendente', value: 'ASC' },
                { text: 'Texto', value: 'TEXT' } ],"hide-details":"","background-color":"transparent"},model:{value:(index.type),callback:function ($$v) {_vm.$set(index, "type", $$v)},expression:"index.type"}})],1),_c('td',[_c('v-checkbox',{staticClass:"my-2 mx-3",attrs:{"hide-details":""},model:{value:(index.unique),callback:function ($$v) {_vm.$set(index, "unique", $$v)},expression:"index.unique"}})],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeIndex(i)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)])}),0)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-btn',{staticClass:"mx-0",attrs:{"dark":"","block":"","color":"#59a4f4"},on:{"click":_vm.addIndex}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Añadir Índice ")],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.updateCollection}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"#59a4f4","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }