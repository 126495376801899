import { ButtonFragment } from '@/components/componentTypes/buttons/fragments'
import { Button } from '@/models'
import getClient from '@/plugins/vue-apollo/client'
import store from '@/store'
import { GraphQLError } from 'graphql'
import gql from 'graphql-tag'
import invokeButton from '.'

export default async function invokeButtonId (buttonId : string, params : Record<string, any>) {
  try {
    const client = getClient()
    const { data } = await client.query({
      query: gql`query getButton ($buttonId : ID) {
        button(buttonId: $buttonId) {
          ...Button
        }
      }
      ${ButtonFragment}
      `,
      variables: {
        buttonId
      }
    })
    return invokeButton(data.button as Button, params)
  } catch (e) {
    let message = e.message
    if (e.graphQLErrors) message = e.graphQLErrors.map((e : GraphQLError) => e.message).join(', ')
    await store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + message,
      color: 'error',
      timeout: 10000
    })
    console.error(e)
  }
}
