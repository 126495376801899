
















import { Component, Prop, Vue } from 'vue-property-decorator'
import _snakeCase from 'lodash/snakeCase'
import { Table } from '@/models'
import { ViewTableCell, ViewTableRow } from '.'
import BlockView from '@/components/componentTypes/blocks/View.vue'

@Component({
  components: {
    BlockView
  }
})
export default class TableInlineBlock extends Vue {
  @Prop({ type: Object, required: true }) table !: Table
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Object, required: true }) row !: ViewTableRow
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>
  @Prop({ type: String, required: true }) blockId !: string

  busy = false

  /** Combined View and Item params */
  get itemParams () : Record<string, any> {
    return {
      ...this.viewParams,
      _id: this.row.raw._id,
      ...this.row.raw.data
    }
  }
}
