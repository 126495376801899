

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FilterWithSchema } from '@/models'
import Fields from './Fields.vue'
import _pickBy from 'lodash/pickBy'

interface FilterValue {
  filterId ?: string
  filterOptions : Record<string, any>
}

@Component({
  components: {
    Fields
  }
})
export default class FilterForm extends Vue {
  @Prop({ type: Object, required: true }) value !: FilterValue
  @Prop({ type: Array, required: true }) filters !: FilterWithSchema[]
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>

  currentOptions = {}

  mounted () {
    this.currentOptions = this.value ? this.value.filterOptions : {}
  }

  get currentFilter () {
    return this.filters.find(f => f._id === this.value.filterId)
  }

  get selectFilters () {
    return this.filters.map(f => ({ text: f.title, value: f._id }))
  }

  get currentFilterId () {
    return this.value && this.value.filterId || ''
  }

  set currentFilterId (newFilterId : string | undefined) {
    this.currentOptions = {}
    this.$emit('input', { filterId: newFilterId, filterOptions: { ...this.viewParams } })
  }

  @Watch('viewParams')
  refreshOptions () {
    this.updateOptions(this.currentOptions)
  }

  @Watch('currentOptions')
  updateOptions (newOptions : Record<string, any>) {
    // Clean empty fields
    const newFields = _pickBy({ ...newOptions, ...this.viewParams }, (value, key) => {
      if (!this.currentFilter || !this.currentFilter.schema || !this.currentFilter.schema[key]) return false
      return value != null && value !== ''
    })
    this.$emit('input', { ...this.value, filterOptions: newFields })
  }
}
