import { Button } from '@/models'
import getClient from '@/plugins/vue-apollo/client'
import store from '@/store'
import { GraphQLError } from 'graphql'
import gql from 'graphql-tag'
import parseButtonParameters from './parseButtonParameters'
import handleButtonSuccess from './handleButtonSuccess'

export default async function invokeButton (button : Button, params : Record<string, any>) {
  if (!button || !params) return
  const client = getClient()
  try {
    const { data } = await client.mutate({
      mutation: gql` mutation buttonRunHooks($button: JSON, $parameters: JSON, $singular: Boolean) {
        result: buttonRunHooks(button: $button, parameters: $parameters, singular: $singular)
      }`,
      variables: {
        button: button,
        parameters: parseButtonParameters(button, params),
        singular: true
      }
    })
    await handleButtonSuccess(button, params, data.result)
  } catch (e) {
    let message = e.message
    if (e.graphQLErrors) message = e.graphQLErrors.map((e : GraphQLError) => e.message).join(', ')
    await store.dispatch('snackbar/showSnackbar', {
      text: button.onErrorMessage || 'Error: ' + message,
      color: 'error',
      timeout: 10000
    })
    console.error(e)
  }
}
