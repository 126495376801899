





































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import XLSX from 'xlsx'
import gql from 'graphql-tag'
import { prompt } from '@/components/dialogs'

@Component({})
export default class CollectionEditAdvanced extends Vue {
  @Prop({ type: String, required: true }) environmentId !: string
  @Prop({ type: String, required: true }) collectionId !: string

  working = false

  async downloadJsonSchema () {
    if (this.working) return
    try {
      this.working = true
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation downloadSchema ($collectionId : ID, $format : String) {
          schema: downloadSchema(collectionId: $collectionId, format: $format)
        }`,
        variables: {
          collectionId: this.collectionId,
          format: 'json'
        }
      })
      const { schema } = data
      var link = document.createElement("a")
      link.download = `${this.collectionId}.schema.json`
      link.href = URL.createObjectURL(new Blob([schema], { type: 'application/json' }))
      link.click()
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar exportar el schema'
      })
    } finally {
      this.working = false
    }
  }

  async downloadTsSchema () {
    if (this.working) return
    try {
      this.working = true
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation downloadSchema ($collectionId : ID, $format : String) {
          schema: downloadSchema(collectionId: $collectionId, format: $format)
        }`,
        variables: {
          collectionId: this.collectionId,
          format: 'ts'
        }
      })
      const { schema } = data
      var link = document.createElement("a")
      link.download = `${this.collectionId}.ts`
      link.href = URL.createObjectURL(new Blob([schema], { type: 'application/x-typescript' }))
      link.click()
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar exportar el schema'
      })
    } finally {
      this.working = false
    }
  }

  async exportHeaders () {
    if (this.working) return
    try {
      this.working = true
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation exportHeaders ($collectionId : ID) {
          headers: exportHeaders(collectionId: $collectionId)
        }`,
        variables: {
          collectionId: this.collectionId
        }
      })
      const { headers } = data
      XLSX.writeFile(headers, `${this.collectionId}.xlsx`)
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar exportar las cabeceras'
      })
    } finally {
      this.working = false
    }
  }

  async clearData () {
    if (this.working) return
    const confirm = await prompt(
      `ADVERTENCIA: Esto borrará todos los registros de ${this.collectionId}!\n` +
      'Esta operación no se puede deshacer.\n\n' +
      'Escribe "Eliminar Datos" abajo si estás seguro.',
      {
        okButtonText: 'Eliminar',
        okButtonIcon: 'delete',
        okButtonColor: 'error'
      }
    ) as string
    if (!confirm || confirm?.toLowerCase() !== 'eliminar datos') {
      return this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se canceló el borrado de datos.'
      })
    }
    try {
      this.working = true
      await this.$apollo.mutate({
        mutation: gql`mutation removeDataCollection ($collectionId : ID) {
          removeDataCollection(collectionId: $collectionId)
        }`,
        variables: {
          collectionId: this.collectionId
        }
      })
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se vaciaron los registros de la colección.'
      })
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar vaciar los registros.'
      })
    } finally {
      this.working = false
    }
  }


  async deleteCollection () {
    if (this.working) return
    const confirm = await prompt(
      `ADVERTENCIA: Esto borrará por completo la colección ${this.collectionId}!\n` +
      'Esta operación no se puede deshacer. Adicionalmente, cualquier lógica que dependa de esta colección se romperá.\n\n' +
      `Escribe "${this.collectionId}" abajo si estás completamente seguro.`,
      {
        okButtonText: 'Eliminar',
        okButtonIcon: 'delete',
        okButtonColor: 'error'
      }
    ) as string
    if (!confirm || confirm?.toLowerCase() !== this.collectionId) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se canceló el borrado de la colección.'
      })
    }
    try {
      this.working = true
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation removeCollection ($collectionId : ID) {
          removeCollection(collectionId: $collectionId)
        }`,
        variables: {
          collectionId: this.collectionId
        }
      })
      this.$emit('delete', data.removeCollection)
    } catch (e) {
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar vaciar los registros.'
      })
    } finally {
      this.working = false
    }
  }

  get adminLink () {
    if (window.mobileApp) return ''
    if (process.env.VUE_APP_ADMIN_DOMAIN_V1 && this.environmentId && this.collectionId) {
      return `${document.location.protocol}//${process.env.VUE_APP_ADMIN_DOMAIN_V1}/${this.environmentId}/collections/${this.collectionId}`
    }
    return ''
  }
}
