var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.externalData && !_vm.table.disableRefresh)?_c('CollectionWatcher',{attrs:{"environmentId":_vm.table.environmentId,"collectionId":_vm.table.collectionId},on:{"update":_vm.update}}):_vm._e(),(_vm.hasMultiSelect)?_c('v-layout',{staticClass:"table-buttons",class:{ 'table-buttons-active': _vm.selectedFields.length > 0 },staticStyle:{"width":"100%"},attrs:{"align-left":""}},[_c('span',{staticClass:"my-4 pa-2"},[_vm._v(_vm._s(_vm.selectedFields.length)+" seleccionado"+_vm._s(_vm.selectedFields.length === 1 ? '' : 's'))]),_vm._l((_vm.buttons),function(button,i){return [(button.type === 'group')?_c('v-menu',{key:i,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-4",attrs:{"color":"primary","disabled":_vm.selectedFields.length <= 0}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(button.label)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("keyboard_arrow_down")])],1)]}}],null,true)},[_c('v-list',_vm._l((button.buttonIds),function(buttonId){return _c('TableButton',{key:buttonId,attrs:{"btnstyle":"menuItem","buttonId":buttonId,"environmentId":_vm.table.environmentId,"preview":_vm.preview,"viewParams":_vm.viewParams,"selectedItems":_vm.selectedFields,"tableId":_vm.table._id,"filterId":_vm.filterId,"filterOptions":_vm.filterOptions},on:{"success":function($event){_vm.selectedFields = []}}})}),1)],1):_c('TableButton',{key:button.buttonId,attrs:{"buttonId":button.buttonId,"environmentId":_vm.table.environmentId,"preview":_vm.preview,"viewParams":_vm.viewParams,"selectedItems":_vm.selectedFields,"tableId":_vm.table._id,"filterId":_vm.filterId,"filterOptions":_vm.filterOptions},on:{"success":function($event){_vm.selectedFields = []}}})]}),_vm._t("default")],2):_vm._e(),_c('v-data-table',{staticClass:"transparent",class:{ loading: !_vm.disableLoadAnimation && (_vm.$apollo.loading || _vm.externalLoading || _vm.internalLoading) },staticStyle:{"padding-top":"1em"},attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","options":_vm.pagination,"disable-sort":_vm.table.sortable,"footer-props":{
      disableItemsPerPage: _vm.table.unlimited,
      disablePagination: _vm.table.unlimited,
      itemsPerPageOptions: _vm.table.unlimited ? undefined : [10, 25, 50, 100, 200]
    },"server-items-length":_vm.pagination.totalItems,"loading":!_vm.disableLoadAnimation && (_vm.$apollo.loading || _vm.externalLoading || _vm.internalLoading),"show-select":_vm.hasMultiSelect,"mobile-breakpoint":0,"fixed-header":_vm.fixedHeader,"expanded":_vm.expandedFields},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expandedFields=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){return [(!_vm.table.unlimited)?_c('v-text-field',{staticStyle:{"width":"20em","font-size":"97%"},attrs:{"type":"number","min":"1","max":_vm.totalPages,"prefix":"Página","suffix":("de " + _vm.totalPages),"rules":_vm.pageRules},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('div',{staticClass:"align-center my-2 pl-1",staticStyle:{"width":"10em"},attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" "+_vm._s(_vm.totalItems)+" registros ")])]):_vm._e()]}}]),model:{value:(_vm.selectedFields),callback:function ($$v) {_vm.selectedFields=$$v},expression:"selectedFields"}},[_c('draggable',{attrs:{"slot":"body","tag":"tbody","value":_vm.items,"handle":".row-handle","disabled":!_vm.table.sortable,"animation":200,"scroll-sensitivity":128,"force-fallback":true},on:{"change":_vm.processManualSort},slot:"body"},[_vm._l((_vm.items),function(item){return _c('tr',{key:item.id,staticClass:"table-item",class:item.cssClasses,attrs:{"active":_vm.isSelected(item)}},[_vm._l((item.cols),function(tableField,index){
    var _obj;
return _c('td',{key:tableField.key,class:( _obj = {}, _obj[tableField.class] = true, _obj ),attrs:{"width":tableField.width}},[_c('TableField',{attrs:{"table":_vm.table,"fieldIndex":tableField.colIndex,"row":item,"cell":tableField,"viewParams":_vm.viewParams,"preview":_vm.preview,"isSelected":_vm.isSelected(item),"isExpanded":_vm.isExpanded(item, index)},on:{"expand":function($event){return _vm.expand(item, index)},"collapse":function($event){return _vm.collapse(item)},"update":_vm.update,"toggleSelection":function($event){return _vm.toggleSelection(item, $event)},"singleSelect":function($event){return _vm.$emit('singleSelect', $event)}}})],1)}),(item.expandSection)?_c('td',{staticClass:"inline-block",attrs:{"colspan":_vm.headers.length}},[_c('InlineBlock',{attrs:{"table":_vm.table,"row":item,"viewParams":_vm.viewParams,"preview":_vm.preview,"blockId":item.blockId}})],1):_vm._e()],2)}),_vm._l((_vm.footerItems),function(footerRow,i){return _c('tr',{key:i,staticStyle:{"font-size":"90%"}},[(!footerRow.items || footerRow.items.length <= 0)?_c('td',{attrs:{"colspan":_vm.headers.length}}):_vm._e(),_vm._l((footerRow.items),function(item,index){return _c('td',{key:index,attrs:{"colspan":index >= footerRow.items.length - 1 ? _vm.headers.length - index : 1}},[(item.type === 'text')?_c('b',{domProps:{"textContent":_vm._s(item.text)}}):_vm._e(),(item.type === 'parameter')?_c('b',{domProps:{"textContent":_vm._s(_vm.viewParams[item.parameter])}}):_vm._e(),(item.type === 'indicator')?_c('b',[_c('IndicatorResult',{attrs:{"indicatorId":item.indicatorId,"filterOptions":_vm.viewParams,"viewParams":_vm.viewParams}})],1):_vm._e()])})],2)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }