


























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { RenderedBlock, ViewItem, Block, User } from '@/models'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'
import { ApolloError } from 'apollo-client'
import xlsx from 'xlsx'
import FormView from '@/components/componentTypes/forms/View.vue'
import invokeButtonId from '@/utils/invokeButton/invokeButtonId'
import { showDialog } from '@/components/dialogs'
import openLink from '@/utils/route/openLink'
import { update } from 'lodash'
import FilterForm from '@/components/form/FilterForm.vue'
import numeral from 'numeral'

interface FilterValue {
  filterId ?: string
  filterOptions : Record<string, any>
}

@Component({
  name: 'BlockView',
  components: {
    Loading,
    ComponentHeader,
    CollectionWatcher,
    FormView,
    FilterForm
  },
  apollo: {
    rendered: {
      query: gql`query getContent ($blockId : ID, $preview : Boolean, $params : JSON, $filterId : ID, $filterOptions : JSON) {
        rendered: renderBlock (
          blockId: $blockId,
          preview: $preview,
          params: $params,
          filterId: $filterId,
          filterOptions: $filterOptions
        )
      }`,
      fetchPolicy: 'network-only',
      variables () {
        return {
          blockId: this.componentId,
          params: {
            ...this.viewParams,
            page: this.page,
            limit: this.limit
          },
          filterId: this.filter && this.filter.filterId,
          filterOptions: this.filter && this.filter.filterOptions,
          preview: this.preview
        }
      },
      error (e : ApolloError) {
        this.error = e
      },
      result ({ data }) {
        if (data) {
          this.error = null
          this.fallbackBlock = null
        }
      }
    },
    fallbackBlock: {
      query: gql`query getFallback ($blockId : ID) {
        fallbackBlock: block (blockId: $blockId) {
          _id
          environmentId
          name
          title
        }
      }`,
      fetchPolicy: 'network-only',
      variables () {
        return {
          blockId: this.componentId
        }
      },
      skip () {
        return !!this.rendered
      }
    }
  }
})
export default class BlockView extends Vue {
  @Prop({ type: String }) environmentId !: string
  @Prop({ type: String }) componentId !: string
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Boolean, default: false }) editing !: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) itemDefinition !: ViewItem
  @Prop({ type: Boolean, default: false }) showCloseButton !: boolean

  rendered : Readonly<RenderedBlock> | null = null
  fallbackBlock : Block | null = null
  error : ApolloError | null = null
  exporting = false
  modalOpen = false
  runningButton = false
  isPendingDestroy = false
  filter : FilterValue | null = null

  get limit () {
    return this.pagination.itemsPerPage || parseInt(this.viewParams.limit) || this.block?.defaultLimit || 10
  }

  get page () {
    return this.pagination.page || parseInt(this.viewParams.page) || 1
  }

  set page (page : number) {
    this.pagination = {
      ...this.pagination,
      page: Math.max(1, Math.min(page, this.totalPages))
    }
  }

  get totalPages () {
    return Math.ceil(this.pagination.itemsLength / (this.pagination.itemsPerPage || 1))
  }

  get totalItems () {
    return numeral(this.pagination.itemsLength).format('0,000')
  }

  get pageRules () {
    return [
      (v : number) => !!v && v >= 1 && v <= this.totalPages || 'Página inválida'
    ]
  }
  
  pagination = {
    page: undefined as number | undefined,
    itemsPerPage: undefined as number | undefined,
    sortBy: [] as string[],
    sortDesc: [] as boolean[],
    itemsLength: 0,
    multiSort: false
  }

  linkHandler = (e : MouseEvent) => {
    e.preventDefault()
    const link = e.currentTarget as HTMLAnchorElement
    if (this.preview || this.runningButton || this.isPendingDestroy) return
    const href = link.getAttribute('href') || ''
    if (href.startsWith('#md-')) {
      const parts = link.hash.split('-')
      const modalComponentType = parts[1]
      const modalComponentId = parts[2]
      const modalComponent = ({
        'block': BlockView,
        'form': FormView
      } as Record<string, any>)[modalComponentType]
      let modalAdditionalParams = {}
      try {
        modalAdditionalParams = JSON.parse(link.dataset.params || '{}')
      } catch (e) {
        console.error('Error parsing custom modal params:', e)
      }

      showDialog({
        id: link.hash,
        component: modalComponent,
        wrapInCard: true,
        props: {
          environmentId: this.environmentId,
          componentId: modalComponentId,
          itemDefinition: {
            id: modalComponentId,
            type: modalComponentType,
            sizeSmall: '12',
            sizeLarge: '12',
            blockId: modalComponentId,
            namespace: modalComponentType + 's'
          },
          preview: this.editing,
          showCloseButton: true,
          viewParams: {
            ...this.viewParams,
            ...modalAdditionalParams
          }
        },
        events: {
          setParams: (params : any) => {
            this.$emit('setParams', params)
          }
        },
        dismissable: true,
        class: `md-${modalComponentType}-${modalComponentId}`,
        handler (result : boolean) {
        }
      })

    } else if (href.startsWith('#btn-')) {
      const parts = link.hash.split('-')
      this.runButton(parts[1], link.dataset.params || '{}').catch()
    } else if (href.startsWith('#set-params')) {
      this.$emit('setParams', JSON.parse(link.dataset.params || '{}'))
    } else if (href && href !== '#') {
      openLink(href, this.viewParams, link.target === '_blank')
    }
    return false
  }

  beforeDestroy () {
    this.isPendingDestroy = true
  }

  @Watch('rendered')
  bindContentEvents (rendered : Readonly<RenderedBlock> | null) {
    if (!rendered) return
    this.$nextTick(() => {
      const content = this.$refs.content as HTMLDivElement
      // Update links
      const links = content.getElementsByTagName('a')
      for (const link of links) {
        link.onclick = null
        link.removeEventListener('click', this.linkHandler)
        link.addEventListener('click', this.linkHandler)
      }
    })
    this.setDefaultFilter(rendered.block)
    this.updatePagination(rendered)
  }

  setDefaultFilter (block : Block) {
    if (this.filter) return
    if (block.filterByDefault) {
      this.filter = { filterId: block.filterByDefault, filterOptions: this.viewParams }
    } else if (block.allowsNoFilter) {
      this.filter = { filterId: undefined, filterOptions: this.viewParams }
    } else if (block.filters && block.filters.length >= 1) {
      this.filter = { filterId: block.filters[0]._id, filterOptions: this.viewParams }
    }
  }

  updatePagination (rendered : RenderedBlock) {
    this.pagination = {
      page: this.page,
      itemsPerPage: this.limit,
      sortBy:  [rendered.block.orderBy || ''],
      sortDesc: [!rendered.block.orderByAsc],
      itemsLength: rendered.totalItems || 0
    } as any
  }

  async update () {
    this.error = null
    await this.$apollo.queries.rendered.refetch()
  }

  async exportBlock () {
    if (!this.rendered || !this.block) return
    this.exporting = true
    const workbook = xlsx.utils.book_new()
    const tables = (this.$refs.content as HTMLDivElement).getElementsByTagName('table')
    let sheet : any
    for (const table of tables) {
      if (!sheet) {
        sheet = xlsx.utils.table_to_sheet(table)
        continue
      }
      const ref = xlsx.utils.decode_range(sheet['!ref'])
      ref.e.r += 2
      sheet['!ref'] = xlsx.utils.encode_range(ref)
      // @ts-ignore
      xlsx.utils.sheet_add_dom(sheet, table, { origin: -1 })
    }
    xlsx.utils.book_append_sheet(workbook, sheet, this.block.title || this.block.name || 'Hoja1')
    xlsx.writeFile(workbook, `${this.block.title || this.block.name || 'Documento'}.xlsx`)
    this.exporting = false
  }

  async runButton (buttonId : string, paramsStr : string) {
    if (this.preview || this.runningButton || this.isPendingDestroy) return
    console.log(this.$router.currentRoute.path, this.$route.path)
    let params = { ...this.viewParams }
    try {
      const additionalParams = JSON.parse(paramsStr)
      Object.assign(params, additionalParams)
    } catch (e) {
      console.error(e)
    }
    this.runningButton = true
    await invokeButtonId(buttonId, params)
    this.$nextTick(() => {
      this.runningButton = false
    })
  }

  get block () {
    return this.rendered && this.rendered.block || this.fallbackBlock
  }

  get content () {
    return this.rendered && this.rendered.content
  }

  get user () : User {
    return this.$store.state.auth.user
  }

  get isAdmin () {
    return this.user.roles.indexOf('admin') >= 0 || this.user.roles.indexOf('superAdmin') >= 0
  }

  get errorMessage () {
    if (!this.error) return ''
    return this.error.message.split('\n').slice(1).join('\n')
  }
}
