import gql from 'graphql-tag'

export const ButtonUpdateFragment = gql`
  fragment ButtonUpdate on Button {
    name
    roles
    title
    afterHooksIds
    onSuccessMessage
    onErrorMessage
    hookResult
    shouldStopHooksOnError
    itemNumberResult
    firstHook
    lastHook
    buttonType
    largeHitbox
    buttonText
    icon
    color
    style
    url
    requireTwoFactor
    goBack
    hsmHookId
    postItemToUrl
    helperType
    parameters {
      parameterName
      value
    }
    setViewParameters {
      parameterName
      value
    }
  }
`

export const ButtonCreateFragment = gql`
  fragment ButtonCreate on Button {
    _id
    environmentId
    createdAt
  }
`

export const ButtonFragment = gql`
  fragment Button on Button {
    ...ButtonCreate
    ...ButtonUpdate
  }

  ${ButtonCreateFragment}
  ${ButtonUpdateFragment}
`
