










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Collection } from '@/models'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import { CollectionCreateFragment } from '@/components/componentTypes/collections/fragments'
import EditFields from '@/components/componentTypes/collections/EditFields.vue'
import EditAdvanced from '@/components/componentTypes/collections/EditAdvanced.vue'
import EditIndexes from '@/components/componentTypes/collections/EditIndexes.vue'
import EditBrowse from '@/components/componentTypes/collections/EditBrowse.vue'

@Component({
  components: {
    EditBrowse,
    EditIndexes,
    EditAdvanced,
    Loading,
    EditFields
  },
  apollo: {
    collection: {
      query: gql`query getCollection($collectionId: ID) {
        collection (collectionId: $collectionId) {
          ...CollectionCreate
        }
      }
      ${CollectionCreateFragment}`,
      variables () {
        return {
          collectionId: this.componentId
        }
      }
    }
  }
})
export default class CollectionEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId !: string
  @Prop({ type: String, required: true }) componentId !: string
  @Prop({ type: String, default: 'component' }) currentTab !: string

  collection : Partial<Collection> | null = null

  mounted () {
    this.$emit('additionalTabs', {
      component: 'Campos',
      indexes: 'Índices',
      browse: 'Visualizar / Modificar Datos',
      advanced: 'Avanzado'
    })
  }

  @Watch('collection')
  updateName (collection : Collection) {
    this.$emit('name', collection.name)
  }
}
